import React from "react"

export default function Locations() {
  return (
    <div className="contact-locations">
      <p>
        <strong>Contact Us</strong>
      </p>
      <div className="contacts">
        <div className="location-block">
          <p className="location-title">Vaughan</p>
          <p className="location-text">
            1-164 Buttermill,
            <br />
            Vaughan, ON, L4K 3X6
            <br />
            Canada
            <a href="tel:+14169913767">(416) 991-3767</a>
          </p>
        </div>
        <div className="location-block">
          <p className="location-title">Midland - Main Office</p>
          <p className="location-text">
            531 King Street,Unit B,
            <br />
            Midland, ON L4R 3N6
            <br />
            Canada
            <a href="tel:+17055277767">(705) 527-7767</a>
          </p>
        </div>
        <div className="location-block">
          <p className="location-title">Barrie - Sales Office</p>
          <p className="location-text">
            81 Toronto St., Unit 2,
            <br />
            Barrie, ON L4N 1V1
            <br />
            Canada
            <a href="tel:+17057344199">(705) 734-4199</a>
          </p>
        </div>
      </div>
    </div>
  )
}
