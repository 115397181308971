import React from "react"
import { Link } from "gatsby"
import { Container, Grid } from "@material-ui/core"
import Locations from "./../locations"

import Logo from "../../images/footer_logo.png"
// import IoFacility from '../../images/ion-facility.svg'
// import IoPromo from '../../images/io-promo.svg'
// import IoPrint from '../../images/io-print.svg'
// import E6 from '../../images/e6.svg'
// import IonGroupLogo from '../../images/iongroup-logo.svg'
import ScrollToTop from "react-scroll-to-top"
import ScrollTop from "./scroll-top"

import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import { Instagram } from "@material-ui/icons"

export default function Footer() {
  return (
    <>
      <footer>
        <div className="black-gradient white-text">
          <Container className="py-40" maxWidth="xl">
            <Grid items container className="flex justify-between">
              <Grid md={6} sm={12}>
                <Link to="/">
                  <img src={Logo} className="logo" alt="PROS Marketing Logo" />
                </Link>
                <p>
                  Pros Marketing is dedicated to maintaining its small business,
                  customer-focused roots while expanding services to empower our
                  clients and customers on a global scale.
                </p>
                <p className="flex mt-5 social-icons justify-center md:justify-start">
                  <a
                    href="https://www.facebook.com/PROSMARKETING/"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-5"
                    title="Visit us on Facebook"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://twitter.com/prosmarketing"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-5"
                    title="Visit us on Twitter"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/prosmarketing/"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-5"
                    title="Visit us on Instagram"
                  >
                    <Instagram />
                  </a>
                </p>
              </Grid>
              <Grid md={5} xs={12}>
                <Locations />
              </Grid>
            </Grid>
            {/* <div className="full-logo-row pt-40">
                <a href="https://iongroup.ca/" target="_blank"><img src={IonGroupLogo} className="iongroup-logo" /></a>
              </div>
              <div className="footer-socials">
                <a href="https://ionfs.ca/" target="_blank" title="Visit Ion Facility Services" rel="noreferrer"><img src={IoFacility} alt="Facility" /></a>
                <a href="https://ioprint.ca/" target="_blank" title="Visit Imageon Promotional" rel="noreferrer"><img src={IoPromo} alt="Promo" /></a>
                <a href="https://www.iopromo.ca/" target="_blank" title="Visit Imageon Print" rel="noreferrer"><img src={IoPrint} alt="Print" /></a>
                <a href="https://element6.io/" target="_blank" title="Visit Element6" rel="noreferrer"><img src={E6} alt="Web Development" /></a>
              </div> */}
          </Container>
        </div>
        <div className="blue-bg footer-bottom white-text py-12">
          <Container className="md:flex md:justify-between">
            <p>
              Copyright &copy; 2024 Pros Marketing All rights reserved.{" "}
              <span>
                | <Link to="/privacy-policy">Privacy Policy</Link>
              </span>
            </p>
            <ScrollToTop
              smooth
              className="scroll-top"
              component={<ScrollTop />}
              style={{
                position: "initial",
                width: "auto",
                background: "transparent",
              }}
            />
          </Container>
        </div>
      </footer>
    </>
  )
}
